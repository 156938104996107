.card-solutions {
  width: 23%;

  border: 1px solid var(--grey);
  border-radius: 4px;

  padding: 20px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
}
.card-solutions-title {
  font-weight: 500;
  background-color: var(--grey);
  width: 100px;
  height: 100px;
  border-radius: 50%;

  font-size: 2rem;
  text-align: center;
  line-height: 3;
  margin: 10px 10px 50px 10px;
}

.card-solutions h4 {
  font-weight: 500;
  font-size: 1rem;
  margin: 16px 16px;
}
.card-solutions p {
  font-weight: 500;
  font-size: 0.75rem;
  margin: 16px 16px;

  color: var(--pink);
}
.card-solutions ul {
  margin-left: 28px;
}
.content-solutions li {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--dark-grey);
  padding-bottom: 4px;
}
.card-solutions button {
  font-size: 1rem;
  font-weight: 500;
  display: block;
  width: 95%;
  height: 48px;

  background-color: var(--green);

  border: none;
  border-radius: 6px;
  margin: 20px auto;

  transition: filter 0.2s;
}

.card-solutions button:hover {
  filter: brightness(0.9);
}

