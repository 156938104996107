:root {
  --green: #03ffa5;
  --pink: #ee0e7d;
  --black: #000000;

  --grey: #f2f2f2;
  --dark-grey: #5b5b5b;

  --white: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  @media (max-width: 1080px) {
    font-size: 93.75%;
  }
  @media (max-width: 720px) {
    font-size: 87.5%;
  }
}

body,
input,
textarea,
button {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 600;
}
